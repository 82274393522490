/* eslint-disable */
import css from 'styled-jsx/css';

const styles = css`
  .loader {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1300;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.8);
    color: black;
  }

  .loader-content {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    width: 100%;
    text-align: center;
  }

  .loader-spinner {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .loader-spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #bbb;
    border-radius: 50%;
    animation: loader-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #bbb transparent transparent transparent;
  }
  .loader-spinner div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .loader-spinner div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .loader-spinner div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes loader-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default styles;
