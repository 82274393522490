/* eslint-disable */
import css from 'styled-jsx/css';

import COLORS from '@utils/colors';

const styles = css`
  :global(.monochrome) {
    :global(.icon-default-search_svg__shade) {
      fill: ${COLORS.black900};
      opacity: 0.16;
    }
  }
  :global(.no-fill) {
    :global(.icon-default-search_svg__shade) {
      opacity: 0;
    }
  }
  :global(.icon-search.color-gray) {
    :global(path) {
      stroke: ${COLORS.black600};
    }
  }
  :global(.icon-search.color-blue) {
    :global(path) {
      stroke: ${COLORS.primary};
    }
  }
`;

export default styles;
