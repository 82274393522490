import { useAppSelector } from '@hooks/useAppSelector';
import { NeighborCampaigns } from '@services/hafh/types/generated';

export const useIsNeighborCampaignActiveAndEligible = (
  campaignName: keyof NeighborCampaigns
) => {
  const { campaigns } = useAppSelector((state) => state.campaign);

  const campaign = campaigns?.[campaignName];

  return !!(campaign?.status?.active && campaign?.status?.eligible);
};

export const GLOBAL_PROMOTION_TAB_KEYS = ['taipei', 'others'];

export const GLOBAL_PROMOTION_CAMPAIGN_PROPERTIES = {
  others: [3534, 2425, 2428, 544, 2859],
  taipei: [
    2465, 2449, 2453, 2452, 2451, 2338, 2698, 2422, 2423, 2421, 2424, 1325,
    1337, 319,
  ],
};

export const GLOBAL_PROMOTION_CAMPAIGN_TOPIC_IDS: {
  [key: string]: number;
} = {
  en: 20_783,
  ja: 20_775,
  ko: 20_785,
  'zh-tw': 20_787,
};

export const GLOBAL_PROMOTION_CAMPAIGN_END_DATE = '2024-08-31T23:59:59+0900';
