import React, { useState } from 'react';
import IconHeartLine from '@assets/icon-heart-line.svg';
import MediaQuery from '@components/Layout/MediaQuery';
import Link from '@components/Link';
import { useAppSelector } from '@hooks/useAppSelector';
import useLocation from '@hooks/useLocation';
import useRegistrationCountry from '@hooks/useRegistrationCountry';
import IconWithText from '@molecules/IconWithText';
import NavIconList from '@molecules/NavIconList';
import IconFlight from '@svg-icons/IconFlight';
import IconSearch from '@svg-icons/IconSearch';
import IconUser from '@svg-icons/IconUser';
import { BREAKPOINT } from '@utils/constants';
import routes from '@utils/routes';
import { isEmpty } from '@utils/utils';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

const SigninDialog = dynamic(
  () => import('@components/molecules/SignInDialog')
);

const BottomNavigation = () => {
  const [showSigninDialog, setShowSigninDialog] = useState(false);
  const { pathname } = useRouter();
  const { t } = useTranslation('nav-icons');
  const { neighbor } = useAppSelector((state) => ({
    neighbor: state.neighbor,
  }));
  const signedIn = !isEmpty(neighbor);
  const [dialogType, setDialogType] = useState<
    'favorite' | 'flightReservation'
  >('favorite');

  const { isJapan } = useLocation();
  const { isRegistrationCountryJapan } = useRegistrationCountry();

  const showFlight = isEmpty(neighbor) ? isJapan : isRegistrationCountryJapan;

  return (
    <MediaQuery media={`(max-width: ${BREAKPOINT.mobile}px)`}>
      <nav className="bottom-nav">
        {signedIn ? (
          <NavIconList />
        ) : (
          <>
            <div className="icons">
              <Link href={routes.browse}>
                <IconWithText
                  Icon={() => (
                    <IconSearch
                      color={
                        pathname === routes.properties ||
                        pathname === routes.browse
                          ? 'blue'
                          : 'gray'
                      }
                      withFill={false}
                    />
                  )}
                  isActive={
                    pathname === routes.properties || pathname === routes.browse
                  }
                  text={t('search')}
                />
              </Link>
              <button
                onClick={() => {
                  setDialogType('favorite');
                  setShowSigninDialog(true);
                }}
                type="button"
              >
                <IconWithText
                  Icon={IconHeartLine}
                  className="favorite"
                  text={t('favorites')}
                />
              </button>
              {showFlight && (
                <button
                  onClick={() => {
                    setDialogType('flightReservation');
                    setShowSigninDialog(true);
                  }}
                  type="button"
                >
                  <IconWithText
                    Icon={() => (
                      <IconFlight
                        color={
                          pathname.includes(routes.v2FlightReservationsNew)
                            ? 'blue'
                            : 'gray'
                        }
                        withFill={false}
                      />
                    )}
                    className={t('flight')}
                    text={t('flightSearch')}
                  />
                </button>
              )}
              <Link href={routes.signin}>
                <IconWithText
                  Icon={() => <IconUser color="gray" withFill={false} />}
                  text={t('login')}
                />
              </Link>
            </div>
            <SigninDialog
              dialogType={dialogType}
              redirectPath={pathname}
              setShow={setShowSigninDialog}
              show={showSigninDialog}
            />
          </>
        )}
        <style jsx={true}>{styles}</style>
      </nav>
    </MediaQuery>
  );
};

export default BottomNavigation;
