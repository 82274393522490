import React from 'react';
import Button from '@atoms/Button';
import { TypoXSBold } from '@atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import Link from '@components/Link';
import LoadingOverlay from '@components/LoadingOverlay';
import Header from '@components/Page/Header';
import { useAppSelector } from '@hooks/useAppSelector';
import { useNeighbor } from '@hooks/useNeighbor';
import useRegistrationCountry from '@hooks/useRegistrationCountry';
import CampaignCountdown from '@molecules/CampaignCountdown';
import BottomNavigation from '@organisms/BottomNavigation';
import * as loaderActions from '@redux/modules/loader';
import * as neighborActions from '@redux/modules/neighbor';
import { useIsNeighborCampaignActiveAndEligible } from '@utils/campaign';
import COLORS from '@utils/colors';
import { useActions } from '@utils/hooks';
import reservationUtils from '@utils/reservation';
import routes from '@utils/routes';
import classNames from 'classnames';
import moment from 'moment';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

const Footer = dynamic(() => import('./Footer'));
const FooterSimplified = dynamic(() => import('./Footer/FooterSimplified'));

const Alert = dynamic(() => import('@atoms/Alert'));

const StickyBanners = dynamic(() => import('@organisms/StickyBanners'));

const showBottomNavPages = [
  routes.browse,
  routes.coinHistory,
  routes.favoriteProperties,
  routes.properties,
  routes.inbox,
  routes.profile,
  routes.reservations,
  routes.v2FlightReservationsNew,
];

type Props = {
  bgColor?: keyof typeof COLORS;
  children?: React.ReactNode;
  classNameOption?: any;
  customFooter?: 'simplified' | null;
  isHeaderLogoClickDisabled?: boolean;
  isHeaderLogoH1?: boolean;
  isHeaderOnlyLogo?: boolean;
  isMapView?: boolean;
  showFooter?: boolean;
  showHeader?: boolean;
};

const Page = ({
  bgColor,
  children,
  classNameOption,
  customFooter = null,
  isHeaderLogoClickDisabled,
  isHeaderLogoH1,
  isHeaderOnlyLogo,
  isMapView,
  showFooter = true,
  showHeader = true,
}: Props) => {
  const router = useRouter();
  const { pathname, push, query } = router;

  const { lang, t } = useTranslation('common');

  const {
    authUser,
    message,
    neighbor,
    reservations: {
      upcoming: { reservations: upcomingReservations },
    },
  } = useAppSelector((state) => ({
    authUser: state.authUser,
    campaign: state.campaign,
    message: state.message,
    neighbor: state.neighbor,
    reservations: state.reservations,
  }));

  const { cancelUnsubscribeNeighbor, startLoader, stopLoader } = useActions({
    ...neighborActions,
    ...loaderActions,
  });

  const { isRegistrationCountryJapan } = useRegistrationCountry();
  const { isSnooze } = useNeighbor();

  const isWelcomeBackActiveAndEligible =
    useIsNeighborCampaignActiveAndEligible('welcome_back');

  const handleCancelUnsubscribe = async () => {
    if (window.confirm(t('confirmCancelUnsubscribe'))) {
      startLoader();
      await cancelUnsubscribeNeighbor(authUser, lang);
      stopLoader();
      push(routes.profile);
    }
  };

  const handleAlert = () => {
    const csConversation = message.conversations[0];

    if (
      pathname === routes.inboxDetail &&
      query.tab === 'property' &&
      (query.id === 'cs' ||
        (csConversation && Number(query.id) === csConversation.id))
    ) {
      return (
        <Alert color="blue">
          <Trans
            components={{
              a: <Link className="alert-link" href={routes.support} />,
            }}
            i18nKey="common:conciergeMessage"
          />
        </Alert>
      );
    }

    if (pathname === routes.inbox && query.tab === 'property') {
      return (
        <Alert color="blue">
          <Trans
            components={{
              a: (
                <Link
                  className="alert-link"
                  href={routes.faq}
                  target="_blank"
                />
              ),
            }}
            i18nKey={`common:${isRegistrationCountryJapan ? 'jpFaq' : 'faq'}`}
          />
        </Alert>
      );
    }

    if (neighbor.status === 'to_be_unsubscribed') {
      const lastDate = neighbor.neighbor_plans
        ? moment(neighbor.neighbor_plans[1].start_date).format('LL')
        : '';

      return (
        <Alert color="blue">
          {t('availableLimit', { lastDate })}&nbsp;
          <button onClick={handleCancelUnsubscribe} type="button">
            {t('cancelUnsubscribe')}
          </button>
        </Alert>
      );
    }
    const hasPendingReservation = upcomingReservations.find((r) =>
      reservationUtils.isReservationPendingGuest(r)
    );

    if (hasPendingReservation && pathname === routes.browse) {
      return (
        <Alert color="blue">
          <Link className="alert-link" href={routes.reservations}>
            {t('pendingSplitReservation')}
          </Link>
        </Alert>
      );
    }

    return null;
  };

  const showBottomNav = showBottomNavPages.includes(pathname) && !isMapView;
  const isPageToShowWelcomeBackBanner = [
    routes.browse,
    routes.profile,
    routes.property,
    routes.v2FlightReservationsNew,
    routes.favoriteProperties,
    routes.inbox,
  ].includes(pathname);

  return (
    <LoadingOverlay>
      <div
        className={classNames(
          'page',
          { 'with-header': showHeader },
          { 'with-bottom-nav': showBottomNav },
          bgColor,
          classNameOption
        )}
      >
        {showHeader && (
          <Header
            isLogoClickDisabled={isHeaderLogoClickDisabled}
            isLogoH1={isHeaderLogoH1}
            isOnlyLogo={isHeaderOnlyLogo}
          />
        )}
        {isPageToShowWelcomeBackBanner &&
          (isWelcomeBackActiveAndEligible ? (
            <Link className="welcome-back-header" href={routes.welcomeBack}>
              <div className="welcome-back-campaign">
                <Mobile>
                  <Image
                    alt="Welcome Back header"
                    height={60}
                    quality={100}
                    src="/images/campaign/welcome-back/sticky-banner-sp.png"
                    style={{
                      height: 'auto',
                      width: '100%',
                    }}
                    width={375}
                  />
                </Mobile>
                <Desktop>
                  <Image
                    alt="Welcome Back header"
                    height={60}
                    quality={100}
                    src="/images/campaign/welcome-back/sticky-banner-pc.png"
                    style={{
                      height: 'auto',
                      width: '100%',
                    }}
                    width={1800}
                  />
                </Desktop>
              </div>
            </Link>
          ) : isSnooze ? (
            <div className="welcome-back-header">
              <div className="welcome-back-snooze">
                <div className="welcome-back-text">
                  <TypoXSBold
                    color="white"
                    text={t('welcomeBackHeader.text1')}
                  />
                  <Mobile>
                    <br />
                  </Mobile>
                  <TypoXSBold
                    color="white"
                    text={t('welcomeBackHeader.text2')}
                  />
                </div>
                <Button
                  btnStyle="white"
                  label={t('welcomeBackHeader.buttonText')}
                  link={routes.welcomeBack}
                />
              </div>
            </div>
          ) : null)}
        <main
          className={classNames('page-body', {
            'with-alert': handleAlert(),
          })}
        >
          {pathname !== routes.browse && <CampaignCountdown />}
          {handleAlert()}
          {children}
        </main>
        {customFooter === 'simplified' && <FooterSimplified />}
        {showFooter && customFooter === null && <Footer />}
        <StickyBanners withBottomNav={showBottomNav} />
      </div>
      {showBottomNav && <BottomNavigation />}
      <style jsx={true}>{styles}</style>
    </LoadingOverlay>
  );
};

export default Page;
