/* eslint-disable */
import classNames from 'classnames';
import React from 'react';

import { SVG_SIZES } from '@utils/constants';

import IconFlightSVG from '@assets/new/icon-flight.svg';

import styles from './css';

type IconProps = {
  size?: 'md' | 'lg';
  color?: 'gray' | 'white' | 'purple' | 'blue' | 'black';
  withFill?: boolean;
};

const IconFlight = ({
  size = 'md',
  color = 'gray',
  withFill = true,
}: IconProps) => {
  const style = {
    width: SVG_SIZES[size],
    height: SVG_SIZES[size],
  };

  return (
    <>
      <IconFlightSVG
        className={classNames('icon-flight', `color-${color}`, {
          'no-fill': !withFill,
        })}
        style={style}
      />
      <style jsx>{styles}</style>
    </>
  );
};

export default IconFlight;
