/* eslint-disable */
import moment from 'moment';

import { FlightReservation } from '@utils/types';

import { FLIGHT_RESERVATION_DATES, MAX_DAYS_IN_ADVANCE } from './constants';

export const isInvited = (flightReservation: FlightReservation) => {
  return flightReservation.status === 'invited';
};

export const getFirstAvailableFlightDate = (date?: string) => {
  const startAvailableDate = moment(date)
    .startOf('day')
    .subtract(FLIGHT_RESERVATION_DATES, 'days');
  // we do not allow user reserve flight next day after 23:00
  const reservationLimit = moment().add(1, 'hour').startOf('day').add(1, 'day');
  return moment.max(startAvailableDate, reservationLimit);
};

export const getLastAvailableFlightDate = (date?: string) => {
  if (!date) return moment().add(MAX_DAYS_IN_ADVANCE + 1, 'days');
  const endAvailableDate = moment(date).add(
    FLIGHT_RESERVATION_DATES + 1,
    'days'
  );
  const reservationLimit = moment().add(MAX_DAYS_IN_ADVANCE + 1, 'days');
  return moment.min(endAvailableDate, reservationLimit);
};
