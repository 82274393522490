/* eslint-disable */
import moment from 'moment';

import { isInvited } from '@utils/flight-reservation';
import utils from '@utils/utils';

const reservationUtils = {
  isReservationRequested(reservation) {
    return reservation.status === 'requested';
  },

  isReservationAdjusting(reservation) {
    return reservation.status === 'adjusting';
  },

  isReservationConfirmed(reservation) {
    return reservation.status === 'confirmed';
  },

  isReservationDeclined(reservation) {
    return reservation.status === 'declined';
  },

  isReservationCancelled(reservation) {
    return reservation.status === 'canceled';
  },

  isReservationDiscarded(reservation) {
    return reservation.status === 'discarded';
  },

  isReservationPending(reservation) {
    return reservation.status === 'pending';
  },

  isReservationPendingHost(reservation) {
    return (
      reservation.status === 'pending' &&
      reservation.split_reservation &&
      reservation.representative
    );
  },

  isReservationPendingGuest(reservation) {
    return (
      reservation.status === 'pending' &&
      reservation.split_reservation &&
      !reservation.representative
    );
  },

  isReservationCancellable(reservation) {
    let cancellable = false;
    if (
      this.isReservationRequested(reservation) ||
      this.isReservationAdjusting(reservation) ||
      this.isReservationPendingHost(reservation) ||
      this.isReservationPendingGuest(reservation)
    ) {
      cancellable = moment(reservation.check_in_date).isSameOrAfter(
        utils.timeZoneTokyo().format('YYYY-MM-DD')
      );
    } else if (this.isReservationConfirmed(reservation)) {
      cancellable = moment(reservation.check_in_date).isAfter(
        utils.timeZoneTokyo().format('YYYY-MM-DD')
      );
    }

    return cancellable;
  },
};

export default reservationUtils;

export const checkAboutBookOnTheDay = (startDateStr) => {
  const currentDate = moment().format('YYYY-MM-DD');
  if (currentDate === startDateStr) {
    return utils.isBetween(
      `${startDateStr} 18:00:00`,
      `${startDateStr} 20:59:59`
    );
  }
  return false;
};

export const checkUnableBookOnTheDay = (startDateStr) => {
  const currentDate = moment().format('YYYY-MM-DD');
  if (currentDate === startDateStr) {
    return utils.isBetween(
      `${startDateStr} 21:00:00`,
      `${startDateStr} 23:59:59`
    );
  }
  return false;
};

export const isReservable = (checkInDate) => {
  return moment().isBefore(moment(`${checkInDate} 21:00:00`), 'second');
};

export const getColorByStatus = (reservation) => {
  if (reservationUtils.isReservationRequested(reservation)) return 'gold700';
  if (reservationUtils.isReservationAdjusting(reservation)) return 'gold700';
  if (reservationUtils.isReservationConfirmed(reservation)) return 'purple700';
  if (reservationUtils.isReservationDeclined(reservation)) return 'black700';
  if (reservationUtils.isReservationCancelled(reservation)) return 'black700';
  if (reservationUtils.isReservationDiscarded(reservation)) return 'black700';
  if (reservationUtils.isReservationPendingHost(reservation)) return 'purple700';
  if (reservationUtils.isReservationPendingGuest(reservation)) return 'red500';
  if (isInvited(reservation)) return 'purple700';
};
