/* eslint-disable */
import classNames from 'classnames';
import React from 'react';

import { SVG_SIZES } from '@utils/constants';

import IconSearchSVG from '@assets/new/icon-default-search.svg';

import styles from './css';

type IconProps = {
  size?: 'sm' | 'md' | 'lg';
  monochrome?: boolean;
  withFill?: boolean;
  color?: 'black' | 'gray' | 'blue';
};

const IconSearch = ({
  size = 'md',
  monochrome = true,
  withFill = true,
  color = 'black',
}: IconProps) => {
  const style = {
    width: SVG_SIZES[size],
    height: SVG_SIZES[size],
  };

  return (
    <>
      <IconSearchSVG
        style={style}
        className={classNames(
          'icon-search',
          { monochrome: monochrome && withFill },
          { 'no-fill': !withFill },
          { [`color-${color}`]: color }
        )}
      />
      <style jsx>{styles}</style>
    </>
  );
};

export default IconSearch;
